@import "../../../variables";
.planCard-wrapper {
	margin: 20px;
	border-radius: 5px;
	transition: 0.3s all;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	background: rgba(73, 80, 87, 0.19);

	box-shadow: -5px 10px 10px rgba(0, 0, 0, 0.25);
	border-radius: 10px;

	> .title-container {
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: left;
		justify-content: center;
		color: #ff7276;
		> h3 {
			font-size: 22px;
			margin: 0;
		}
		> h5 {
			margin: 0;
		}
	}
	> div {
		padding: 20px;
	}
	select {
		padding: 0.375rem 1.75rem 0.375rem 0.75rem;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #ff7276;
		width: 240px;
		padding: 12px;
		border: 3px solid #ffffff;
		margin-top: 6px;
		margin-bottom: 16px;
		resize: vertical;
		background-color: #ffffff;
		border-radius: 10px;
		font-size: 16px;
		outline: none;
	}
	> ul {
		list-style-type: none;
		margin: 2rem 0;
		font-size: 85%;
		width: 240px;
		> li {
			position: relative;
			padding: 5px 0;
		}
	}
	button {
		margin-bottom: 20px;
		background: #ff7276;
		border-radius: 11px;
		font-weight: 700 !important;
		font-size: 20px;
		padding: 14px 28px;
		border: none;
		outline: 0;
		border-radius: 50px;
		color: #ffffff;
		> a {
			text-decoration: none;
			color: #ffffff;
			width: 100%;
		}
	}
	button:hover {
		background-color: #dd5a5d;
		> a {
			font-size: 16px;
			color: #ffffff;
			text-decoration: none;
			font-weight: 600;
		}
	}
}
.planCard-wrapper:hover {
	background: rgba(255, 188, 202, 0.19);
	box-shadow: -5px 10px 10px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 760px) {
	.planCard-wrapper {
		margin: 10px;
	}
}
