.tos {
	top: 100px;
	position: relative;
	color: #fff;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-bottom: 50px;
	> div {
		width: 80%;
		h1 {
			margin-bottom: 14px;
			margin-top: 14px;
			font-size: 38px;
			text-align: center;
		}
		h4 {
			font-size: 28px;
		}
		> p {
			font-size: 14px;
		}
		a {
			text-decoration: none;
			color: #dbd3c5;
		}
	}
}
