@import "../../variables";
.faq-wrapper {
	display: flex;
	flex-direction: column;

	width: 100%;
	align-items: center;
	.faq-container {
		max-width: $appmaxwidth;
		width: 90%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		padding: 0;
		padding-bottom: 50px;
		> h5 {
			margin-bottom: 10px;
			color: #fff;
			font-weight: 700;
			line-height: 1.2;
			font-size: 32px;
		}
		> h2 {
			margin-top: 0;
			margin-bottom: 4rem;
			font-weight: 500;
			line-height: 1.2;
			font-size: 14px;
			color: #7d7b7b;
		}
		span {
			color: #ff7276;
		}
		.answer-qestion {
			border-bottom: 1px solid rgba(255, 255, 255, 0.274);
		}
	}
	a {
		color: white;
		text-decoration: none;
	}
}
