@import "../../variables";
.features-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
	padding: 70px 0;

	// background-image: url(../../images/bg1.png);
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	> .features-container {
		max-width: $appmaxwidth;
		width: 90%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		button {
			background: #dd5a5d;

			outline: 0;
			color: white;
			padding: 8px 20px;
			border-radius: 8px;
			cursor: pointer;
			margin-top: 40px;
		}
		button:hover {
			background-color: #a73133;
		}
		span {
			color: #ff7276;
		}
		.purple {
			background-color: #e65aae;
		}
		p {
			color: #a9a9a9;

			span {
				color: #8c85da;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.features-wrapper {
		> .features-container {
			display: flex;
			flex-direction: column;
		}
	}
}
