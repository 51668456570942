@import "../../variables";
.footer-wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;

	.footer-content {
		max-width: $appmaxwidth;
		width: 90%;
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		padding: 0;
		padding-bottom: 50px;

		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
		}

		a {
			color: white;
			text-decoration: none;
			font-weight: 400;
			font-size: 16px;
			line-height: 18px;
			cursor: pointer;
		}
		.link {
			color: #cccccc;
		}
	}
}

@media screen and (max-width: 808px) {
	.footer-wrapper {
		.footer-content {
			display: flex;
			flex-direction: column;
		}
	}
}
