@import "../../variables";
.home-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;

	.home-container {
		max-width: $appmaxwidth;
		width: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
		padding: 80px 50px;
		color: white;
		.right {
			width: 50%;
			h3 {
				font-weight: 700;
				font-size: 36px;
				line-height: 53px;
				text-transform: uppercase;
			}
			h6 {
				color: #ff7276;
				font-size: 18px;
			}
			p {
				font-weight: 400;
				font-size: 14px;
				line-height: 30px;
				margin-right: 50px;
			}
			button {
				background: #dd5a5d;

				outline: 0;
				color: white;
				padding: 8px 20px;
				border-radius: 8px;
				cursor: pointer;
				margin-top: 40px;
				transition: all 0.3s;
			}
			button:hover {
				background-color: #a73133;
			}
			#outline {
				background: transparent;
				border: 1px solid #dd5a5d;
				outline: 0;
				color: #ff003d;
				padding: 8px 20px;
				transition: all 0.3s;
				border-radius: 8px;
				cursor: pointer;
				margin-top: 40px;
			}
			#outline:hover {
				background-color: #dd5a5d;
				color: white;
			}
		}
		.left {
			width: 50%;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			> img {
				width: 100%;
				-webkit-animation: mover 1s infinite alternate;
				animation: mover 1s infinite alternate;
			}
			> img {
				width: 100%;
				-webkit-animation: mover 1s infinite alternate;
				animation: mover 1s infinite alternate;
			}
			@-webkit-keyframes mover {
				0% {
					transform: translateY(0);
				}
				100% {
					transform: translateY(-30px);
				}
			}
			@keyframes mover {
				0% {
					transform: translateY(0);
				}
				100% {
					transform: translateY(-30px);
				}
			}
		}
	}
}
@media screen and (max-width: 1000px) {
	.home-wrapper {
		.home-container {
			display: flex;
			flex-direction: column;
			.left {
				width: 100%;
				p {
					margin-right: 0;
				}
			}
			.right {
				width: 100%;
				margin-top: 50px;
				img {
					width: 70%;
				}
			}
		}
	}
}
